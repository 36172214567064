import _ from "lodash";

type ListModuleReducerState = {
  currentFilter: FilterType;
  filtering: FilterType[];
  sorting: GridSortItem[];
  page: number;
  pageSize: number;
  totalPages: number;
  totalItems: number;
};

const initialState: ListModuleReducerState = {
  currentFilter: { column: "", value: "", operator: "" },
  filtering: [] as FilterType[],
  sorting: [] as GridSortItem[],
  page: 1,
  pageSize: 10,
  totalPages: 0,
  totalItems: 0,
};

type SetPaginationInfoType = { totalPages: number; totalItems: number };
type SetPageAction = { type: "SET_PAGE"; value: number };
type SetPageSizeAction = { type: "SET_PAGE_SIZE"; value: number };
type SetPaginationInfoAction = { type: "SET_PAGINATION_INFO"; value: SetPaginationInfoType };
type SetSortingAction = { type: "SET_SORTING"; value: GridSortItem[] };
type AddFilteringAction = { type: "ADD_FILTERING"; value: { filter: FilterType; isMultiple: boolean } };
type SetFilteringAction = { type: "SET_FILTERING"; value: FilterType[] };
type DeleteFilteringAction = { type: "DELETE_FILTERING"; value: FilterType };
type SetCurrentFilterAction = { type: "SET_CURRENT_FILTER"; value: FilterType };
type ResetFilterAction = { type: "RESET_FILTER" };

type ListModuleAction =
  | SetSortingAction
  | AddFilteringAction
  | SetFilteringAction
  | DeleteFilteringAction
  | SetCurrentFilterAction
  | SetPageAction
  | SetPageSizeAction
  | SetPaginationInfoAction
  | ResetFilterAction;

export default function listModuleReducer(state = initialState, action: ListModuleAction): ListModuleReducerState {
  switch (action.type) {
    case "SET_PAGE": {
      return { ...state, page: action.value };
    }
    case "SET_PAGE_SIZE": {
      return { ...state, pageSize: action.value };
    }
    case "SET_PAGINATION_INFO": {
      return { ...state, totalPages: action.value?.totalPages || 0, totalItems: action.value?.totalItems || 0 };
    }
    case "SET_SORTING": {
      return { ...state, sorting: action.value };
    }
    case "SET_FILTERING": {
      return { ...state, filtering: action.value };
    }
    case "ADD_FILTERING":
      if (action.value.isMultiple) {
        return { ...state, filtering: [...state.filtering, action.value.filter] };
      } else {
        const filtering = [
          ...state.filtering.filter((x) => {
            return x.column !== action.value.filter.column || x.operator !== action.value.filter.operator;
          }),
          action.value.filter,
        ];
        return { ...state, filtering };
      }
    case "DELETE_FILTERING": {
      return {
        ...state,
        filtering: [...state.filtering.filter((x) => !_.isEqual(x, action.value))],
      };
    }
    case "SET_CURRENT_FILTER": {
      return { ...state, currentFilter: action.value };
    }
    case "RESET_FILTER": {
      return initialState;
    }
    default:
      return state;
  }
}
