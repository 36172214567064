import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import configReducer from "./configReducer";
import appReducer from "./appReducer";
import loginReducer from "./loginReducer";
import moduleStructureReducer from "./moduleStructureReducer";
import moduleReducer from "./moduleReducer";
import listModuleReducer from "./listModuleReducer";
import profileReducer from "./profileReducer";
import userReducer from "./userReducer";
import clientReducer from "./clientReducer";

export type StateType = ReturnType<typeof rootReducer>;

const rootReducer = combineReducers({
  configReducer,
  appReducer,
  loginReducer,
  moduleStructureReducer,
  moduleReducer,
  listModuleReducer,
  profileReducer,
  userReducer,
  clientReducer,
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
export default store;
