const TYPES = {
  //Common
  SET_IS_LOADING: "SET_IS_LOADING",

  // Config
  SET_THEME: "SET_THEME",
  SET_NAME: "SET_NAME",
  SET_LOGO: "SET_LOGO",

  // Module Structure
  SET_ALL_MODULES: "SET_ALL_MODULES",
  SET_KEY_CLIENT: "SET_KEY_CLIENT",
  SET_MODULES: "SET_MODULES",
  SET_PERMISSIONS: "SET_PERMISSIONS",
  SET_EXTERNAL_VALUES_DATA: "SET_EXTERNAL_VALUES_DATA",
  SET_ADMIN: "SET_ADMIN",

  // Module
  SET_IS_LOADING_MODULE: "SET_IS_LOADING_MODULE",
  MERGE_FORM_VALIDATION: "MERGE_FORM_VALIDATION",
  SET_FORM_VALIDATION: "SET_FORM_VALIDATION",
  SET_VALUES: "SET_VALUES",
  SET_ITEM: "SET_ITEM",
  SET_MODULE: "SET_MODULE",
  SET_DATA_MODULE: "SET_DATA_MODULE",
  SET_FUNCTION: "SET_FUNCTION",
  SET_READY_TO_RENDER: "SET_READY_TO_RENDER",
  SET_RELOAD_DATA: "SET_RELOAD_DATA",

  // List Module
  SET_PAGE: "SET_PAGE",
  SET_PAGE_SIZE: "SET_PAGE_SIZE",
  SET_PAGINATION_INFO: "SET_PAGINATION_INFO",
  SET_SORTING: "SET_SORTING",
  ADD_FILTERING: "ADD_FILTERING",
  DELETE_FILTERING: "DELETE_FILTERING",
  SET_FILTERING: "SET_FILTERING",
  SET_CURRENT_FILTER: "SET_CURRENT_FILTER",
  RESET_FILTER: "RESET_FILTER",

  // App
  TOGGLE_SLIDE_MENU_OPEN: "TOGGLE_SLIDE_MENU_OPEN",
  TOGGLE_OPTIONS_MENU: "TOGGLE_OPTIONS_MENU",
  ADD_ERROR: "ADD_ERROR",
  REMOVE_ERROR: "REMOVE_ERROR",
  SET_CONFIG_LOADED: "SET_CONFIG_LOADED",

  // Login
  SET_USER: "SET_USER",
  SET_FORM_VALIDATION_LOGIN: "SET_FORM_VALIDATION_LOGIN",

  // Profile
  SET_PROFILES: "SET_PROFILES",
  SET_PERMISSIONS_PROFILE: "SET_PERMISSIONS_PROFILE",
  SET_MODULES_BY_PROFILE: "SET_MODULES_BY_PROFILE",

  // User
  SET_PASSWORD: "SET_PASSWORD",
  SET_REPEAT_PASSWORD: "SET_REPEAT_PASSWORD",
  SET_UPDATE_PASSWORD_INFO: "SET_UPDATE_PASSWORD_INFO",
};

export default TYPES;
