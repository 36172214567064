import Types from "../Constants/Types";
import Modules from "src/Constants/Modules";
import localStorage from "localStorage";

const initialState = {
  isLoading: false,
  module: { key: Modules.Dashboard },
  func: "",
  formValidation: null,
  dataModule: [],
  item: null,
  values: null,
  readyToRender: false,
  reloadData: false,
};

type SetModuleType = { module: ModuleType; mainFunction: string };

type SetValuesType = { [key: string]: any };

type ActionModuleReducerType =
  | { type: typeof Types.SET_IS_LOADING_MODULE; value: boolean }
  | { type: typeof Types.SET_MODULE; value: SetModuleType }
  | { type: typeof Types.SET_FUNCTION; value: string }
  | { type: typeof Types.SET_FORM_VALIDATION; value: FormValidationType }
  | { type: typeof Types.SET_DATA_MODULE; value: string }
  | { type: typeof Types.SET_ITEM; value: any }
  | { type: typeof Types.SET_VALUES; value: SetValuesType };

const isSetModuleType = (value?: SetModuleType | {}): value is SetModuleType => {
  if ((value as SetModuleType).module) return true;
  return false;
};

export default function moduleReducer(state = initialState, action: ActionModuleReducerType) {
  switch (action.type) {
    case Types.SET_IS_LOADING_MODULE: {
      return { ...state, isLoading: typeof action.value === "boolean" ? action.value : false };
    }
    case Types.SET_MODULE: {
      let func = action.value && isSetModuleType(action.value) ? action.value.mainFunction : "";
      localStorage.setItem("func", func);
      return {
        ...state,
        module: action.value && isSetModuleType(action.value) ? action.value.module : "",
        func,
        item: null,
        values: null,
      };
    }
    case Types.SET_READY_TO_RENDER: {
      return { ...state, readyToRender: action.value };
    }
    case Types.SET_FUNCTION: {
      let func = typeof action.value === "string" ? action.value : "";
      localStorage.setItem("func", func);
      return { ...state, func, readyToRender: false };
    }
    case Types.SET_FORM_VALIDATION: {
      return { ...state, formValidation: action.value };
    }
    case Types.MERGE_FORM_VALIDATION: {
      // @ts-ignore
      let formValidation = state.formValidation != null ? { ...(state.formValidation ?? {}), ...action.value } : action.value;
      return { ...state, formValidation };
    }
    case Types.SET_DATA_MODULE: {
      return { ...state, dataModule: Array.isArray(action.value) ? (action.value as never[]) : [], func: "list", readyToRender: false };
    }
    case Types.SET_ITEM: {
      return { ...state, item: action.value, values: action.value === null ? null : state.values };
    }
    case Types.SET_VALUES: {
      return { ...state, values: action.value };
    }
    case Types.SET_RELOAD_DATA: {
      return { ...state, reloadData: action.value };
    }
    default:
      return state;
  }
}
