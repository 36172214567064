import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import Fallback from "src/Components/Fallback";
import store from "src/Reducers/store";

import "src/i18n";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const App = React.lazy(() => import("src/App"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router basename="/">
        <Suspense fallback={<Fallback />}>
          <App />
        </Suspense>
      </Router>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
