import Types from "../Constants/Types";
import { isBrowser } from "react-device-detect";

const initialState = {
  isLoading: false,
  slideMenuOpen: isBrowser,
  optionsMenu: false,
  formValidation: {},
  errors: [],
  configLoaded: "INIT",
};

function isErrorType(action?: ErrorType | {}): action is ErrorType {
  if ((action as ErrorType).key) return true;
  return false;
}

export default function appReducer(state = initialState, action: { type: ""; value?: ErrorType | {} }) {
  switch (action.type) {
    case Types.TOGGLE_OPTIONS_MENU: {
      return { ...state, optionsMenu: !state.optionsMenu };
    }
    case Types.TOGGLE_SLIDE_MENU_OPEN: {
      return { ...state, slideMenuOpen: !state.slideMenuOpen };
    }
    case Types.SET_IS_LOADING: {
      return { ...state, isLoading: action.value };
    }
    case Types.ADD_ERROR: {
      let errors: Array<ErrorType> = [...state.errors];
      if (action.value && isErrorType(action.value)) errors.push({ ...action.value, datetime: Date.now(), opacity: 1 });
      return { ...state, errors };
    }
    case Types.REMOVE_ERROR: {
      let errors: Array<ErrorType> = [];
      state.errors.forEach((error: ErrorType) => {
        if (error.key !== action.value) errors.push(error);
      });
      return { ...state, errors };
    }
    case Types.SET_CONFIG_LOADED: {
      return { ...state, configLoaded: action.value };
    }
    default:
      return state;
  }
}
