import Types from "../Constants/Types";
import { isMobile } from "react-device-detect";

const initialState = {
  name: "",
  logo: "",
  theme: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          head: { backgroundColor: "#e6e6e6", fontWeight: "bold" },
          body: { padding: "12px", paddingLeft: "5px" },
        },
      },
      MuiCheckbox: {
        styleOverrides: { root: { padding: 0 } },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: { marginLeft: 0, marginBottom: "8px", height: "56px" },
        },
      },
      MuiDrawer: {
        styleOverrides: { paper: { position: isMobile ? "absolute!important" : undefined } },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: "#1976d230",
            },
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            width: "100%",
          },
        },
      },
    },
  },
};

export default function configReducer(state = initialState, action: { type: ""; value: {} | null }) {
  switch (action.type) {
    case Types.SET_NAME: {
      return { ...state, name: action.value };
    }
    case Types.SET_LOGO: {
      return { ...state, logo: action.value };
    }
    case Types.SET_THEME: {
      return { ...state, theme: { ...state.theme, ...action.value } };
    }
    default:
      return state;
  }
}
