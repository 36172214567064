import Types from "../Constants/Types";

const initValidationType = {
  value: "",
  error: false,
  helperText: "",
};

const initialState = {
  password: { value: "", error: false, helperText: "" },
  repeatPassword: { value: "", error: false, helperText: "" },
  updatePasswordInfo: {
    current_password: initValidationType,
    new_password: initValidationType,
    confirm_new_password: initValidationType,
  },
};

export default function userReducer(state = initialState, action: { type: string; value: ValidationType }) {
  switch (action.type) {
    case Types.SET_PASSWORD: {
      return { ...state, password: action.value };
    }
    case Types.SET_REPEAT_PASSWORD: {
      return { ...state, repeatPassword: action.value };
    }
    case Types.SET_UPDATE_PASSWORD_INFO: {
      return { ...state, updatePasswordInfo: action.value };
    }
    default:
      return state;
  }
}
