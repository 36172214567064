import Types from "../Constants/Types";

const initialState = {
  user: {},
  formValidationLogin: {
    username: { error: false, helperText: "", value: "superadmin" },
    password: { error: false, helperText: "", value: "cascada2" },
  },
  isLoading: false,
};

export default function loginReducer(state = initialState, action: { type: ""; value: {} | null }) {
  switch (action.type) {
    case Types.SET_USER: {
      return { ...state, user: action.value };
    }
    case Types.SET_FORM_VALIDATION_LOGIN: {
      return { ...state, formValidationLogin: { ...state.formValidationLogin, ...action.value } };
    }
    case Types.SET_IS_LOADING: {
      return { ...state, isLoading: action.value };
    }
    default:
      return state;
  }
}
