import Types from "../Constants/Types";

const initialState = {
  modules: [],
  permissions: {},
  admin: { user: "", profile: "" },
  externalValuesData: {},
};

type ActionModuleStructureReducerType =
  | { type: typeof Types.SET_MODULES; value: ModuleType[] }
  | { type: typeof Types.SET_PERMISSIONS; value: PermissionsType }
  | { type: typeof Types.SET_EXTERNAL_VALUES_DATA; value: ExternalValuesDataType };

export default function moduleStructureReducer(state = initialState, action: ActionModuleStructureReducerType) {
  switch (action.type) {
    case Types.SET_MODULES: {
      return { ...state, modules: action.value };
    }
    case Types.SET_PERMISSIONS: {
      return { ...state, permissions: action.value };
    }
    case Types.SET_ADMIN: {
      return { ...state, admin: action.value };
    }
    case Types.SET_EXTERNAL_VALUES_DATA: {
      return { ...state, externalValuesData: action.value };
    }
    default:
      return state;
  }
}
