import Types from "../Constants/Types";

const initialState = {
  profiles: [],
  permissions: [],
  modules: [],
  //   clients: [],
  //   client: { value: null, error: false, helperText: "" },
};

export default function profileReducer(state = initialState, action: any) {
  switch (action.type) {
    case Types.SET_PROFILES: {
      return { ...state, profiles: action.value };
    }
    case Types.SET_PERMISSIONS_PROFILE: {
      return { ...state, permissions: action.value };
    }
    case Types.SET_MODULES_BY_PROFILE: {
      return { ...state, modules: action.value };
    }
    // case Types.SET_CLIENTS_BY_SELECT_PROFILE: {
    //   return { ...state, clients: action.clients };
    // }
    // case Types.SET_CLIENT_BY_PROFILE: {
    //   return { ...state, client: action.client };
    // }
    default:
      return state;
  }
}
