import Types from "../Constants/Types";

const initialState = {
  modules: [],
};

export default function clientReducer(state = initialState, action: { type: string; value: ModuleType[] }) {
  switch (action.type) {
    case Types.SET_ALL_MODULES: {
      return { ...state, modules: action.value };
    }
    default:
      return state;
  }
}
